#about {
  background: #2b2b2b;
  padding-top: 90px;
  padding-bottom: 66px;
  overflow: hidden;
}

#about a,
#about a:visited {
  color: #fff;
}
#about a:hover,
#about a:focus {
  color: #11abb0;
}

#about h2 {
  font: 22px/30px "opensans-bold", sans-serif;
  color: #fff;
  margin-bottom: 12px;
}
#about h3 {
  font: "opensans-bold", sans-serif;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
#about p {
  line-height: 30px;
  color: #8b8b8b;
}
#about .profile-pic {
  position: relative;
  width: 175px;
  height: 175px;
  border-radius: 100%;
}
#about .contact-details {
  width: 41.66667%;
}

#about .fav-things {
  margin-top: 20px;
}
#about .fav-things div {
  text-align: center;
  margin-bottom: 10px;
}
#about .fav-things iframe,
img {
  width: 235px;
  height: 235px;
  transition: width 1s, height 1s;
}
#about .fav-things img:hover,
#about .fav-things iframe:hover {
  width: 240px;
  height: 240px;
}
#about .fav-things img {
  border-radius: 12px;
}

/* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
  /* About Section
     ------------------------------------------------------------------- */
  #about .profile-pic {
    width: 114px;
    height: 114px;
    margin-left: 12px;
  }
  #about .contact-details {
    width: 50%;
  }
  #about .download {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  /* mobile navigation
     -------------------------------------------------------------------- */
  #about .profile-pic {
    display: block;
    margin: 0 auto 30px auto;
  }
  #about h2 {
    text-align: center;
  }
  #about .download .button {
    width: 100%;
    text-align: center;
    padding: 15px 20px;
  }
  #about .main-col {
    padding-right: 30px;
  }
}
