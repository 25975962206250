#testimonials {
  background: #1f1f1f url(../../assets/images/testimonials-bg.jpg) no-repeat
    center center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed;

  position: relative;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
}
#testimonials .text-container {
  padding-top: 96px;
  padding-bottom: 66px;
}
#testimonials h1 {
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
}
#testimonials h1 span {
  display: none;
}
#testimonials .header-col {
  padding-top: 9px;
}
#testimonials .header-col h1:before {
  font-family: "FontAwesome";
  content: "\f10d";
  padding-right: 10px;
  font-size: 72px;
  line-height: 72px;
  text-align: left;
  float: left;
  color: #fff;
}

/*	Blockquotes */
#testimonials blockquote {
  margin: 0 0px 30px 0px;
  padding-left: 0;
  position: relative;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}
#testimonials blockquote:before {
  content: none;
}
#testimonials blockquote p {
  font-family: "librebaskerville-italic", serif;
  padding: 0;
  font-size: 20px;
  line-height: 48px;
  color: #fff;
}
#testimonials blockquote cite {
  display: block;
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  color: #fff;
}
#testimonials blockquote cite:before {
  content: "\2014 \0020";
}
#testimonials blockquote cite a,
#testimonials blockquote cite a:visited {
  color: #8b9798;
  border: none;
}

.download {
  padding-top: 6px;
  margin-top: 60px;
  text-align: center;
}
.download .button {
  background: #c5c5c5;
  color: #3d3d3d;
}
.download .button:hover {
  background: #a1a1a1;
  color: #000;
}
.download .button i {
  margin-right: 15px;
  font-size: 20px;
}

/* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
  /* Testimonials Section
     ------------------------------------------------------------------- */
  #testimonials .header-col h1:before {
    font-size: 66px;
    line-height: 66px;
  }
  #testimonials blockquote p {
    font-size: 22px;
    line-height: 46px;
  }
}

@media only screen and (max-width: 767px) {
  /* mobile navigation
     -------------------------------------------------------------------- */
  #testimonials .text-container {
    text-align: center;
  }
  #testimonials h1 {
    font: 16px/24px "opensans-bold", sans-serif;
    text-align: center;
    margin-bottom: 30px;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
  }
  #testimonials h1 span {
    display: block;
  }
  #testimonials .header-col h1:before {
    content: none;
  }
  #testimonials blockquote {
    padding-bottom: 24px;
  }
  #testimonials blockquote p {
    font-size: 20px;
    line-height: 42px;
  }

  /* Control Nav */
  .flex-control-nav {
    text-align: center;
    margin-left: -30px;
  }
}
